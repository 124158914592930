.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


::-webkit-scrollbar {
  display: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation-name: float;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.animate-float-first {
  animation-name: float;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.animate-float-second {
  animation-name: float;
  animation-duration: 6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


@keyframes wave {
  0%, 100% {
      border-radius: 50%;
  }
  25% {
      border-radius: 40% 60% 60% 40% / 50% 50% 60% 60%;
  }
  50% {
      border-radius: 60% 40% 40% 60% / 60% 60% 50% 50%;
  }
  75% {
      border-radius: 60% 40% 40% 60% / 50% 50% 60% 60%;
  }
  100% {
      border-radius: 40% 60% 60% 40% / 50% 50% 60% 60%;
  }
}

@keyframes hoverWave {
  0%, 100% {
      border-radius: 50%;
  }
  25% {
      border-radius: 60% 40% 60% 40% / 50% 50% 50% 50%;
  }
  50% {
      border-radius: 40% 60% 40% 60% / 50% 50% 50% 50%;
  }
  75% {
      border-radius: 40% 60% 40% 60% / 50% 50% 50% 50%;
  }
  100% {
      border-radius: 60% 40% 60% 40% / 50% 50% 50% 50%;
  }

}

/* Estilo común para el botón */
.wave-border {
  display: inline-block;
  border: 3px solid #1f2937; /* Ajusta el color del borde según tu diseño */
  border-radius: 50%;
  width: 200px; /* Ajusta según el tamaño deseado */
  height: 200px; /* Ajusta según el tamaño deseado */
  animation: wave 6s linear infinite;
  transition: animation 0.5s ease;
}

/* Estilo para el modo oscuro */
.dark-mode {
  color: #f3f4f6; /* Ajusta el color del texto en modo oscuro */
  border-color: #f3f4f6; /* Ajusta el color del borde en modo oscuro */
  background-color: #374151; /* Ajusta el color de fondo en modo oscuro */
  
}

/* Estilo para el modo claro */
.light-mode {
  color: #1f2937; /* Ajusta el color del texto en modo claro */
  border-color: #1f2937; /* Ajusta el color del borde en modo claro */
  background-color: #ffffff; /* Ajusta el color de fondo en modo claro */
}

/* ... Tus demás estilos ... */

.wave-border:hover {
  animation: hoverWave 3s linear infinite;
  
}

.parallax-layer {
  will-change: transform;
}


/* En tu archivo App.css */
@media (max-width: 768px) {
  .text-9xl {
      font-size: 4rem; /* Ajusta el tamaño del texto para pantallas más pequeñas */
  }

  .wave-border {
      width: 150px; /* Ajusta el tamaño del botón para pantallas más pequeñas */
      height: 150px;
  }
}

html {
  scroll-behavior: smooth;
}