@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Nasalization';
  src: url('./assets/fonts/nasalization-rg.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nasalization';
  src: url('./assets/fonts/Nasalization-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Nasalization';
  src: url('./assets/fonts/Nasalization-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}